<template>
  <div class="modal-card loginModal" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t("user.login.title") }}</p>
    </header>
    <form @submit.prevent="pressed">
      <section>
        <article v-if="error" class="message is-danger">
          <div class="message-body">
            {{ error }}
          </div>
        </article>
      </section>

      <section class="modal-card-body">
        <b-field :label="$t('user.login.email')">
          <b-input
            v-model="email"
            type="email"
            :value="email"
            :placeholder="$t('user.login.yourEmail')"
            required
          >
          </b-input>
        </b-field>

        <b-field :label="$t('user.login.password')">
          <b-input
            v-model="password"
            type="password"
            :value="password"
            password-reveal
            :placeholder="$t('user.login.yourPassword')"
            required
          >
          </b-input>
        </b-field>

        <b-checkbox>{{ $t("user.login.rememberMe") }}</b-checkbox>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="$parent.close()">
          {{ $t("general.close") }}
        </button>
        <b-button @click="pressed" :loading="isLoading" type="is-primary">
          {{ $t("user.login.login") }}
        </b-button>

        <b-button @click="openForgotPassord" class="is-text">{{
          $t("user.login.forgotPassword")
        }}</b-button>
      </footer>
    </form>
  </div>
</template>

<script>
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import modalMixin from "../../mixins/modal";
import helpers from "../../helpers";

import { mapActions } from "vuex";

export default {
  mixins: [modalMixin, helpers],
  data() {
    return {
      isLoading: false,
      email: "",
      password: "",
      error: "",
      Register: Register
    };
  },
  methods: {
    ...mapActions(["signInAction"]),

    async switchView() {
      this.$parent.close();
      setTimeout(() => {
        this.$parent.$parent.openRegisterModal();
      }, 200);
    },

    pressed() {
      this.isLoading = true;
      this.signInAction({
        email: this.email,
        password: this.password
      })
        .then(() => {
          this.isLoading = false;
          this.$parent.close();
          this.$emit("close");
          /*
          if (this.$route.query.nextUrl) {
            this.$router.push(this.$route.query.nextUrl);
          } else {
            this.$router.push({
              name: "Items"
            });
          }*/
        })
        .catch(error => {
          this.isLoading = false;
          this.error = this.firebaseMessage(error);
        });
    },

    openForgotPassord() {
      this.$parent.close();
      this.openModal({
        component: ForgotPassword,
        props: { email: this.email }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
