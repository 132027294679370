<template>
  <div class="container">
    <h1 class="title">
      {{ $t("user.register.title") }}
    </h1>
    <hr />

    <article v-if="error" class="message is-danger">
      <div class="message-body">
        {{ error }}
      </div>
    </article>

    <form @submit.prevent="pressed" autocomplete="off">
      <ValidationObserver ref="observer">
        <div class="columns is-multiline">
          <div class="column is-half">
            <b-field :label="$t('user.register.firstAndLastName')">
              <b-input
                :autocomplete="disableAutofill()"
                tabindex="1"
                type="text"
                name="name"
                v-model="profile.displayName"
                required
              />
            </b-field>
          </div>
          <div class="column is-half">
            <b-field :label="$t('user.register.phoneNumber')">
              <vue-tel-input
                tabindex="2"
                :inputOptions="{ locale: 'nbno' }"
                :autocomplete="disableAutofill()"
                required
                v-model="profile.phoneNumber"
              ></vue-tel-input>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field :label="$t('user.register.streetAddress')">
              <b-autocomplete
                :autocomplete="disableAutofill()"
                v-model="profile.address1"
                tabindex="3"
                :data="addressResults"
                :placeholder="$t('user.register.address')"
                field="adresse"
                :loading="isFetchingAddress"
                @typing="getAddressSuggestion"
                @select="updateAddress"
                :keep-first="true"
                :select-on-click-outside="true"
              >
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      {{ props.option.description }}
                    </div>
                  </div>
                </template>
              </b-autocomplete>
            </b-field>

            <b-field>
              <b-input
                tabindex="4"
                type="text"
                :placeholder="$t('user.register.address2')"
                autocomplete="on"
                name="address"
                v-model="profile.address2"
              />
            </b-field>

            <b-field>
              <b-input
                type="text"
                :autocomplete="disableAutofill()"
                tabindex="4"
                :placeholder="$t('user.register.zipCode')"
                name="postcode"
                v-model="profile.zip"
                required
              />

              <b-input
                type="text"
                :autocomplete="disableAutofill()"
                tabindex="5"
                :placeholder="$t('user.register.city')"
                name="city"
                v-model="profile.city"
                expanded
                required
              />
            </b-field>

            <b-field>
              <div class="control is-expanded">
                <span class="select is-fullwidth">
                  <country-select
                    tabindex="6"
                    v-model="profile.countryCode"
                    :country="profile.countryCode"
                    topCountry="NO"
                    className=""
                  />
                </span>
              </div>
            </b-field>
          </div>

          <div class="column is-half">
            <ValidationProvider
              :rules="`required|email`"
              vid="email"
              name="Email"
              v-slot="{ errors, valid, info }"
            >
              <b-field
                :label="$t('user.register.loginDetails')"
                :type="{
                  'is-danger': errors[0],
                  'is-info': info,
                  'is-success': valid
                }"
                :message="errors"
              >
                <b-input
                  :autocomplete="disableAutofill()"
                  v-model="profile.email"
                  type="email"
                  tabindex="7"
                  name="email"
                  :value="profile.email"
                  :placeholder="$t('user.register.yourEmail')"
                  required
                >
                </b-input
              ></b-field>
              <b-field>
                <b-input
                  :autocomplete="disableAutofill()"
                  v-model="password"
                  type="password"
                  :value="password"
                  password-reveal
                  tabindex="8"
                  :placeholder="$t('user.register.yourPassword')"
                  required
                >
                </b-input>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </ValidationObserver>

      <div class="column is-full has-text-centered">
        <b-button
          tabindex="9"
          class="button is-primary"
          native-type="submit"
          :loading="isLoading"
        >
          {{ $t("user.register.continue") }}
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions } from "vuex";
import helpers from "../../helpers";
import autocompleteAddressMixin from "../../mixins/autocomplete-address";
import Plausible from "plausible-tracker";

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [helpers, autocompleteAddressMixin],
  data() {
    return {
      isLoading: false,
      error: null,

      password: "",
      profile: {
        displayName: "",
        email: "",
        phoneNumber: "",
        address1: "",
        address2: "",
        zip: "",
        city: "",
        countryCode: "NO"
      }
    };
  },

  mounted() {},

  methods: {
    ...mapActions(["signUpAction"]),

    async pressed() {
      this.isLoading = true;

      this.signUpAction({
        email: this.profile.email,
        password: this.password
      })
        .then(() => {
          this.$axios
            .post(
              `${window.apiURL}/profile/new`,
              { data: this.profile },
              this.$axios.config
            )
            .then(res => {
              this.$store.commit("setUserDocument", res.data);
              this.$buefy.toast.open({
                message: "Registrering fullført!",
                type: "is-success"
              });

              // track event plausible
              const { trackEvent } = Plausible();
              trackEvent("newUser");

              this.$router.push({ name: "Home" });
            });
        })
        .catch(error => {
          this.error = this.firebaseMessage(error);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
